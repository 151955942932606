<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <logo />
    </b-link>

    <div class="container mt-5 pt-3 mx-md-5">
      <b-overlay
        :show="showOverlay"
        rounded="sm"
        variant="dark"
      >
        <div>
          <!--  <b-card> -->
          <!-- form -->
          <b-form @submit.prevent>
            <div class="w-100 text-center">
              <b-row>
                <b-col cols="12">
                  <h2 class="mb-1">
                    Request A Dispatch 🚀
                  </h2>
                  <p class="mb-3">
                    1-Click to the nearest delivery service companies, riders,
                    drivers to handle your delivery request!
                  </p>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-1 text-left"
                >
                  <!-- dismissible Alert -->
                  <b-alert
                    v-height-fade.appear
                    variant="danger"
                    dismissible
                    fade
                    :show="showDismissibleAlert"
                    class="mb-0"
                    @dismissed="showDismissibleAlert = false"
                  >
                    <h4 class="alert-heading">
                      {{ error_head }}
                    </h4>
                    <div class="alert-body">
                      <span>{{ error_body }}</span>
                      <div v-if="hasError">
                        <div
                          v-for="(item, index) in errors.data"
                          :key="index"
                        >
                          * <small>{{ item.toString().replace('dispatch_object.', '').replace('_', ' ').replace(/\./g, ' ') }}</small>
                        </div>
                      </div>
                    </div>
                  </b-alert>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-row
                    class="d-flex justify-content-center"
                  >
                    <!--CUSTOMER DETAILS-->
                    <b-col
                      md="4"
                      sm="12"
                      class="text-left"
                    >
                      <b-card>
                        <h4>CUSTOMER DETAILS</h4>
                        <div>
                          <b-row>
                            <!--Search Customers-->
                            <b-col
                              v-if="!isNewCustomer"
                              cols="12"
                            >
                              <b-form-group
                                label="Search"
                                label-for=""
                              >
                                <vue-autosuggest
                                  ref="autocomplete"
                                  v-model="query"
                                  :suggestions="suggestions"
                                  :input-props="inputProps"
                                  :section-configs="sectionConfigs"
                                  :render-suggestion="renderSuggestion"
                                  :get-suggestion-value="getSuggestionValue"
                                  @input="fetchResults"
                                />
                              </b-form-group>
                            </b-col>

                            <!-- New or Old user -->
                            <b-col md="6">
                              <b-form-group
                                label="New Customer"
                                label-for="new-customer"
                              >
                                <b-form-checkbox
                                  v-model="isNewCustomer"
                                  class="custom-control-primary"
                                  name="new-customer"
                                  switch
                                  @change="resetCustomer"
                                >
                                  <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                  </span>
                                  <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                  </span>
                                </b-form-checkbox>
                              </b-form-group>
                            </b-col>

                            <!-- mobile -->
                            <b-col md="6">
                              <b-form-group
                                label="Mobile*"
                                label-for="vi-mobile"
                              >
                                <b-input-group class="input-group-merge">
                                  <b-input-group-prepend is-text>
                                    <feather-icon icon="SmartphoneIcon" />
                                  </b-input-group-prepend>
                                  <b-form-input
                                    id="vi-mobile"
                                    v-model="daas.phone"
                                    type="text"
                                    placeholder="Mobile"
                                    :readonly="!isNewCustomer"
                                  />
                                </b-input-group>
                              </b-form-group>
                            </b-col>

                            <!-- name -->
                            <b-col cols="12">
                              <b-form-group
                                label="Name*"
                                label-for="vi-name"
                              >
                                <b-input-group class="input-group-merge">
                                  <b-input-group-prepend is-text>
                                    <feather-icon icon="UserIcon" />
                                  </b-input-group-prepend>
                                  <b-form-input
                                    id="vi-name"
                                    v-model="daas.name"
                                    type="text"
                                    placeholder="Name"
                                    :readonly="!isNewCustomer"
                                  />
                                </b-input-group>
                              </b-form-group>
                            </b-col>

                            <!-- email -->
                            <b-col cols="12">
                              <b-form-group
                                label="Email"
                                label-for="vi-email"
                              >
                                <b-input-group class="input-group-merge">
                                  <b-input-group-prepend is-text>
                                    <feather-icon icon="MailIcon" />
                                  </b-input-group-prepend>
                                  <b-form-input
                                    id="vi-email"
                                    v-model="daas.email"
                                    type="email"
                                    placeholder="Email(Optional)"
                                    :readonly="!isNewCustomer"
                                  />
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>

                <!--SUBMIT BUTTON-->
                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 btn-sm-block"
                    type="submit"
                    @click="submitDaas"
                  >
                    Submit
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
          <!-- </b-card> -->
        </div>
      </b-overlay>
    </div>
  </div>
  <!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { heightFade } from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import Logo from '@core/layouts/components/Logo.vue'
import { capiUrl } from '@/constants/config'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// date time picker
// import flatPickr from 'vue-flatpickr-component'

// autosuggest
import { VueAutosuggest } from 'vue-autosuggest'
import axios from 'axios'

// places API
// import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  components: {
    Logo,
    BLink,
    BForm,
    BButton,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    VueAutosuggest,
    BOverlay,
    BAlert,
  },

  directives: {
    heightFade,
    Ripple,
  },

  mixins: [heightTransition],

  data() {
    return {
      downImg: require('@/assets/images/pages/coming-soon.svg'),
      /* items: [
          {
            id: 1,
            packages: [{ id: 1, name: "" }],
            nextPackageId: 2,
          },
        ], */
      nextTodoId: 1,
      isNewCustomer: true,
      isInstant: true,

      customer: {
        phone: '',
        name: '',
        email: '',
        id: '',
      },

      daas: {
        name: '',
        phone: '',
        email: '',
        partner: this.$route.params.slug,
      },

      showOverlay: false,

      error_head: 'head',
      error_body: 'body',
      showDismissibleAlert: false,

      // autosuggest
      query: '',
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 250,
      customersUrl: `${capiUrl}search`,
      inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Name, Phone or Email',
        class: 'form-control',
        name: 'ajax',
      },
      suggestions: [],
      sectionConfigs: {
        customers: {
          limit: 6,
          // label: "Customers",
          onSelected: selected => {
            this.selected = selected.item
            this.customer = this.selected
          },
        },
      },
      address: '',
      hasError: false,
      errors: {
        message: '',
        data: [],
      },
    }
  },

  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },

  //   mounted() {
  //     // this.$refs.address.focus();
  //     // this.initTrHeight()
  //   },

  //   created() {
  //     // window.addEventListener('resize', this.initTrHeight)
  //     // console.log(this.$route.params.ref + " created");
  //     // this.getDaas()
  //   },

  //   destroyed() {
  //     // window.removeEventListener('resize', this.initTrHeight)
  //   },

  methods: {
    resetCustomer() {
      this.customer = {
        phone: '',
        name: '',
        email: '',
        id: '',
      }
      if (this.isNewCustomer === true) this.query = ''
    },

    // initTrHeight() {
    //   this.trSetHeight(null)
    //   this.$nextTick(() => {
    //     this.trSetHeight(this.$refs.form.scrollHeight)
    //   })
    // },

    // autosuggest
    fetchResults() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const customersPromise = axios.get(
          `${this.customersUrl}?param=${this.query}`,
        )
        // const photosPromise = axios.get(this.photosUrl);
        // const usersPromise = axios.get(this.usersUrl);

        // Promise.all([photosPromise, usersPromise]).then((values) => {
        Promise.all([customersPromise]).then(() => {
          this.suggestions = []
          this.selected = null
          this.resetCustomer()

          //           const customers = values[0].data
          // customers.length
          //               && this.suggestions.push({ name: 'customers', data: customers })
        })
      }, this.debounceMilliseconds)
    },
    filterResults(data, text, field) {
      return data
        .filter(item => {
          if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return item[field]
          }
          return false
        })
        .sort()
    },
    renderSuggestion(suggestion) {
      if (suggestion.name === 'hotels') {
        const image = suggestion.item
        return (
            <div class="d-flex">
              <div>
                <b-avatar src={image.thumbnailUrl} class="mr-50"></b-avatar>
              </div>
              <div>
                <span>{image.title}</span>
              </div>
            </div>
        )
      }
      return suggestion.item.name
    },
    getSuggestionValue(suggestion) {
      const { name, item } = suggestion
      return name === 'hotels' ? item.title : item.name
    },

    // confirm text
    confirmText() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to return to this dispatch request, after you cancel!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, cancel!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          setTimeout(() => {
            this.$router.replace({ path: '/cancelled' })
          }, 500)
          /* this.$swal({
              icon: "success",
              title: "Cancelled!",
              text: "Your dispatch request has been cancelled.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }); */
        }
      })
    },

    submitDaas(event) {
      this.hasError = false
      this.showOverlay = true

      event.preventDefault()

      // post data
      axios({
        url: `${capiUrl}daas`, // this.$route.params.ref
        data: this.daas,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
      })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Success: ${response.data.message}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          const { hash } = response.data.data

          setTimeout(() => {
            this.$router.replace({
              path: `/${this.$route.params.slug}/${hash}`,
            })
          }, 500)
        })
        .catch(error => {
          this.hasError = true
          // console.log(error.response);
          this.showDismissibleAlert = true
          this.error_head = `Error: ${error.response.statusText}`
          this.error_body = error.response.data.message
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error: ${this.parseError(error.response)}`, // + error.response.statusText ?? error.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.showOverlay = false
        })
    },

    parseError(response) {
      if (response.status === 422) {
        this.errors.message = response.data.message
        this.errors.data = response.data.errors

        return response.data.message
      } if (response.status === 500) {
        this.errors.message = response.data.message
        this.errors.data = []
        return response.status
      }
      return 'this is an error'
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-autosuggest.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  @import "@core/scss/vue/pages/page-misc.scss";
  @import "@core/scss/vue/libs/vue-sweetalert.scss";
  .repeater-form {
    overflow: hidden;
    transition: 0.35s height;
  }
</style>
